// extracted by mini-css-extract-plugin
export var additionalPadding = "ThanksForContact__additionalPadding__ngQOj";
export var button = "ThanksForContact__button__GLqzE";
export var buttonContainer = "ThanksForContact__buttonContainer__E_wMG";
export var buttonContainerHeading = "ThanksForContact__buttonContainerHeading__LJgmV";
export var column = "ThanksForContact__column__Qe6wT";
export var flex = "ThanksForContact__flex__KzzO6";
export var flexColumn = "ThanksForContact__flexColumn__N7vZ3";
export var formContainer = "ThanksForContact__formContainer___kidv";
export var formTitle = "ThanksForContact__formTitle__w54W9";
export var gap1 = "ThanksForContact__gap1__i1q9V";
export var gap2 = "ThanksForContact__gap2__hFBvs";
export var gap3 = "ThanksForContact__gap3__oIKJl";
export var gap4 = "ThanksForContact__gap4__r3qYP";
export var gap5 = "ThanksForContact__gap5__aehpb";
export var layout = "ThanksForContact__layout__IeNLi";
export var row = "ThanksForContact__row__Nw6pQ";